import { SubscriptionStatus } from '@prisma/client';
import { isTextSelection } from '@tiptap/core';
import { Editor } from '@tiptap/react';
import { type ClassValue, clsx } from 'clsx';
import { DOMParser } from 'prosemirror-model';
import { twMerge } from 'tailwind-merge';
import { OrganisationItem } from './types';

function elementFromString(value) {
  const element = document.createElement('div');
  element.innerHTML = value.trim();
  return element;
}

export function insertHTML({ state, view }, value) {
  const { selection } = state;
  const element = elementFromString(value);
  const slice = DOMParser.fromSchema(state.schema).parseSlice(element);
  const transaction = state.tr.insert(selection.anchor, slice.content);

  view.dispatch(transaction);
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function randomColor() {
  const userColors = ['#fb7185', '#fdba74', '#d9f99d', '#a7f3d0', '#a5f3fc', '#a5b4fc', '#f0abfc'];
  return userColors[Math.floor(Math.random() * userColors.length)];
}

export const isTextSelected = ({ editor }: { editor: Editor }) => {
  const {
    state: {
      doc,
      selection,
      selection: { empty, from, to },
    },
  } = editor;

  // Sometime check for `empty` is not enough.
  // Doubleclick an empty paragraph returns a node size of 2.
  // So we check also for an empty text size.
  const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(selection);

  if (empty || isEmptyTextBlock || !editor.isEditable) {
    return false;
  }

  return true;
};

export const isTableGripSelected = (node: HTMLElement) => {
  let container = node;

  while (container && !['TD', 'TH'].includes(container.tagName)) {
    container = container.parentElement!;
  }

  const gripColumn = container && container.querySelector && container.querySelector('a.grip-column.selected');
  const gripRow = container && container.querySelector && container.querySelector('a.grip-row.selected');

  if (gripColumn || gripRow) {
    return true;
  }

  return false;
};

export function removePrefix(value: string) {
  try {
    const id = value.split(':').pop();
    if (!id) return null;
    return id;
  } catch (error) {
    return null;
  }
}
