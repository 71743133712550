export const tiers = [
  {
    name: 'Basic',
    id: 'tier-basic',
    href: '#',
    price: [
      {
        price: '$0',
        priceId: '',
        name: 'Monthly',
        promotion: '',
        priceSuffix: '/month',
      },
      {
        price: '$0',
        priceId: '',
        name: 'Yearly',
        promotion: '',
        priceSuffix: '/month',
      },
    ],
    description: 'The essentials to get you started.',
    features: ['5 documents', 'Up to 500 words daily', 'AI assistant', '48-hour support response time'],
    mostPopular: false,
  },
  {
    name: 'Essential',
    id: 'tier-pro',
    href: '#',
    price: [
      {
        price: '$12',
        priceId: process.env.NEXT_PUBLIC_PRICE_MONTH,
        name: 'Monthly',
        promotion: '',
        priceSuffix: '/month',
      },
      {
        price: '$9',
        priceId: process.env.NEXT_PUBLIC_PRICE_YEAR,
        name: 'Yearly',
        promotion: 'Save 25%',
        priceSuffix: '/month',
      },
    ],
    description: "A plan that scales with you are your team's writing.",
    features: [
      'Unlimited documents',
      'Collaborative writing',
      'AI-Powered writer and researcher',
      'Advance citation',
      '24-hour support response time',
    ],
    mostPopular: true,
  },
  {
    name: 'Business',
    id: 'tier-enterprise',
    href: '#',
    price: [
      {
        price: 'Contact us',
        priceId: '',
        name: 'Monthly',
        promotion: '',
        priceSuffix: '',
      },
      {
        price: 'Contact us',
        priceId: '',
        name: 'Yearly',
        promotion: '',
        priceSuffix: '',
      },
    ],
    description: 'Dedicated support and infrastructure for your company.',
    features: [
      'Unlimited usage',
      'Unlimited subscribers',
      'Integrations',
      '1-hour, dedicated support response time',
      'Marketing automations',
      'Analytics & reporting tools',
      'Advance privacy control',
      'Deploy on business infrastructure',
    ],
    mostPopular: false,
  },
];
