'use client';
import { Field, Label, Radio, RadioGroup } from '@headlessui/react';
import { Check } from 'lucide-react';
import { useState } from 'react';
import { tiers } from '@/lib/stripe';

const frequencies = [
  { value: 0, label: 'Monthly', priceSuffix: '/month' },
  { value: 1, label: 'Annually', priceSuffix: '/month' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Pricing() {
  const [frequency, setFrequency] = useState(frequencies[1].value);

  return (
    <div id="pricing" className="pb-24 sm:pb-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <div className="bg-white rounded-[20px] px-3 py-1 ring-2 ring-gray-500/5 w-fit mx-auto mb-4">Pricing</div>
          <p className="text-2xl sm:text-4xl font-bold text-center">Pricing plans for teams of&nbsp;all&nbsp;sizes</p>
        </div>
        <p className="mt-6 max-w-2xl text-base leading-7 text-gray-600 text-center mx-auto">
          Explore flexible pricing options tailored to suit every need. Whether you're an individual, startup, or
          enterprise, find the perfect plan and unlock full potential of our innovative writing tools.
        </p>
        <div className="mt-8 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-xl p-1 text-center text-sm font-semibold leading-5 ring-1 ring-inset ring-gray-200 bg-white"
            defaultValue={frequency}
          >
            <Label className="sr-only">Payment frequency</Label>
            {frequencies.map(option => (
              <Radio
                key={option.value}
                value={option.value}
                className={({ checked }) =>
                  classNames(checked ? 'bg-primary text-white' : 'text-gray-500', 'cursor-pointer rounded-lg px-5 py-3')
                }
              >
                <span>{option.label}</span>
              </Radio>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-6 lg:max-w-7xl lg:grid-cols-3">
          {tiers.map(tier => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'ring-2 ring-primary/60' : 'ring-2 ring-gray-500/5',
                'rounded-3xl p-8 xl:p-6 bg-white',
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={tier.id}
                  className={classNames(
                    tier.mostPopular ? 'text-primary' : 'text-gray-900',
                    'text-lg font-semibold leading-8',
                  )}
                >
                  {tier.name}
                </h3>
                {tier.mostPopular ? (
                  <p className="rounded-full bg-primary/10 px-2.5 py-1 text-xs font-semibold leading-5 text-primary">
                    Most popular
                  </p>
                ) : null}
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-gray-900">{tier.price[frequency].price}</span>
                <span className="text-sm font-semibold leading-6 text-gray-600">
                  {tier.price[frequency].priceSuffix}
                </span>
              </p>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? 'bg-primary text-white shadow-sm hover:bg-primary/80'
                    : 'text-primary ring-1 ring-inset ring-primary hover:ring-primary',
                  'mt-6 block rounded-lg py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary',
                )}
              >
                Choose {tier.name}
              </a>
              <ul role="list" className="mt-2 space-y-1 text-sm leading-6 text-gray-600 xl:mt-4">
                {tier.features.map(feature => (
                  <li key={feature} className="flex gap-x-3 items-center">
                    <Check className="h-4 w-4 flex-none text-primary" aria-hidden="true" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
