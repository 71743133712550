'use client';
import { useRouter } from 'next/navigation';
import posthog from 'posthog-js';

export default function Hero() {
  const router = useRouter();

  return (
    <div className="relative isolate overflow-hidden">
      <div className="mx-auto max-w-7xl px-6 pb-24 pt-16 sm:pb-32 lg:px-8 lg:py-10 text-center">
        <div className="max-w-2xl lg:max-w-2xl lg:flex-shrink-0 pt-8 lg:pt-16 flex flex-col items-center text-center mx-auto">
          <div>
            <a href="/templates" className="inline-flex space-x-6">
              <span className="rounded-full bg-primary/10 px-3 py-1 text-xs font-semibold leading-6 text-primary ring-1 ring-inset ring-primary/10">
                What's new &bull; Introducting templates
              </span>
            </a>
          </div>
          <h1 className="mt-4 text-3xl font-semibold text-gray-900 sm:text-5xl leading-tight">
            The intelligent platform for <span className="text-primary"> Knowledge </span> and{' '}
            <span className="text-primary"> Productivity! </span>
          </h1>
          <p className="mt-4 text-lg text-gray-800 max-w-xl">
            Simplify your research and writing with our platform that combines your documents and insights, making it
            easy to create polished content quickly.
          </p>
          <div className="flex items-center gap-8">
            <div
              onClick={() => {
                posthog.capture('sign_up_cta');
                router.push('/sign-up');
              }}
              className="cursor-pointer mt-4 flex items-center gap-2 rounded-2xl bg-primary px-4 py-2.5 text-lg font-medium text-white shadow-sm hover:bg-primary/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white transition-all duration-300"
            >
              Get Started – it's free!
            </div>
          </div>
        </div>
        <div className="mx-auto py-16">
          <div className="max-w-3xl flex-none sm:max-w-7xl lg:max-w-none">
            <div className="relative rounded-xl bg-gray-900/5 p-2 lg:rounded-2xl lg:p-4">
              <img src="/penno-landing.png" className="w-full my-auto rounded-lg" alt="Penno AI Dashboard" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
