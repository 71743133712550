'use client';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';

const faqs = [
  {
    id: 1,
    question: 'What is Penno?',
    answer:
      'Penno is an intelligent knowledge management and productivity platform that integrates document organization, real-time collaboration, and smart content creation tools into one seamless interface.',
  },
  {
    id: 2,
    question: "How can Penno help me be more productive?",
    answer:
      'Penno streamlines your workflow by centralizing your documents, enabling real-time collaboration, and offering smart content generation features, allowing you to focus on your core tasks.',
  },
  {
    id: 3,
    question: 'Is Penno suitable for individual users or teams?',
    answer:
      'Yes, Penno is designed for both individual professionals and teams of any size. Our flexible pricing plans cater to different user needs.',
  },
  {
    id: 4,
    question: 'How does import, export and integration works?',
    answer: ' Penno supports multiple file format for export and import including various formats, including .docx, .pdf, .html, .tex and more. Also Penno provides integration based on your tier. We have capability to integrate to other platforms like confluence and also import any public webpage.',
  },
  {
    id: 5,
    question: "How does Penno's smart content generation work?",
    answer:
      "Our advanced AI algorithms analyze your writing style and context to provide suggestions and assist in generating content that aligns with your voice.",
  },
  {
    id: 6,
    question: "What kind of support does Penno offer?",
    answer: "We provide comprehensive support including documentation, video tutorials, email support, and live chat assistance. Premium plans include priority support.",
  },
];

export default function Faq() {
  return (
    <div className="mx-auto max-w-4xl px-6 lg:px-8 flex flex-col items-center">
      <div className="bg-white rounded-[20px] px-3 py-1 ring-2 ring-gray-500/5 w-fit mx-auto mb-4">FAQs</div>
      <h2 className="text-2xl sm:text-4xl font-bold">Frequently asked questions</h2>
      <p className="mt-6 max-w-2xl text-base leading-7 text-gray-600 text-center">
        <span>
          {' '}
          Have a different question and can’t find the answer you’re looking for? Reach out to our support team by{' '}
        </span>
        <a href="mailto:team@penno.io" className="font-semibold text-primary hover:text-primary/90">
          sending us an email
        </a>
        <span> and we’ll get back to you as soon as we can. </span>
      </p>

      <Accordion type="single" collapsible className="w-full pt-14">
        {faqs.map(faq => (
          <AccordionItem value={faq.id.toString()} key={faq.id}>
            <AccordionTrigger className="sm:text-xl py-6 text-start">{faq.question}</AccordionTrigger>
            <AccordionContent>{faq.answer}</AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>

      <div className="mt-20">
        <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10"></dl>
      </div>
    </div>
  );
}
